<template>
  <div>
    <div class="home">
      <div class="tilte_btn">
        <span class="lineY"></span>
        <span>
          <h2>新闻详情</h2>
        </span>
        <span class="m_fl15">
          <el-button @click="getNewEdit" type="danger" size="mini">编辑</el-button>
        </span>
      </div>
      <div style="padding:10px">
        <span class="ent_del">标题：</span>
        <span class="ent_detail">{{webNewInfo.title}}</span>
      </div>
      <div style="padding:10px">
        <span class="ent_del">分类：</span>
        <template>
          <el-radio v-model="radio" :label="radio">{{typetitle}}</el-radio>
        </template>
      </div>
      <div style="padding:10px">
        <el-row>
          <!-- <el-col :span="8">
               <span class="ent_del">作者：</span>
               <span class="ent_detail">{{webNewInfo.author}}</span>
           </el-col>
           <el-col :span="8">
               <span class="ent_del">来源：</span>
               <span class="ent_detail">{{webNewInfo.source}}</span>
           </el-col> -->
          <el-col :span="8">
            <span class="ent_del">发布时间：</span>
            <span class="ent_detail">{{webNewInfo.publishTime}}</span>
          </el-col>
        </el-row>
      </div>
      <!-- <div style="padding:10px">
             <span class="ent_del">新闻缩略图：</span>
             <div style="padding-top:10px">
                <el-upload
                        class="avatar-uploader"
                        action="#"
                        disabled
                        :show-file-list="false">
                        <img v-if="webNewInfo.iconurl" :src="webNewInfo.iconurl" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
            </div>  
     </div> -->
    </div>

    <p style="padding:20px 0 10px 20px;">公告内容：</p>

    <div style="padding:10px;background: #fff;margin:10px">
      <div style="padding: 20px">
        <div v-html="webNewInfo.content"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Web_newDetail',
  /*props: {
       comParam: Object
    },*/
  data () {
    return {
      radio: '',
      webNewInfo: {},
      typetitle: ''
    }
  },
  created () {
    this.comParam = this.$router.currentRoute.query;
    this.LoadnewEdit(this.comParam.id)
    this.getNewType(this.comParam.menid)
  },
  methods: {
    openNewDetail: function () {
      window.open(window.newUrl + '/#/newsPage?ownerID=' + this.comParam.id)
    },
    LoadnewEdit: function (id) {
      var _this = this;
      axios({
        url: _this.API.news.detail + "/" + id,
        method: 'get',
        headers: {
          token: _this.Util.getjwtToken(),
        },
      }).then(function (res) {
        if (res.data.success) {
          _this.webNewInfo = res.data.data
        } else {

        }
      })
    },
    getNewType: function (id) {
      var _this = this;
      axios({
        url: _this.API.news.menuDetail,
        method: 'get',
        headers: {
          token: _this.Util.getjwtToken(),
        },
        params: {
          id: id,
        },
      }).then(function (res) {
        if (res.data.success) {
          console.log(res)
          _this.typetitle = res.data.data.title;
          _this.radio = res.data.data.id;
        } else {

        }
      })
    },
    getNewEdit: function () {
      this.webNewInfo.type = this.comParam.type;
      this.$parent.pullItem("编辑公告", "Web_noticeEdit", this.webNewInfo)
    }
  }
}
</script>
<style lang="scss" scoped>
.tilte_btn {
  background-color: #ffffff;
}
.wz_flex {
  display: flex;
  margin: 20px 0;
  height: 40px;
  align-items: center;
  border-bottom: 1px solid #e1e1e1;
  justify-content: space-between;
}
</style>